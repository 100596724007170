var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"csn-support-sidebar"},[(_vm.isPending)?_c('Loader'):_c('div',_vm._l((_vm.csmDictionary),function(ref,index){
var items = ref.items;
var title = ref.title;
return _c('div',{key:index},[_c('span',{staticClass:"csn-support-sidebar-section"},[_vm._v(" "+_vm._s(title)+" ")]),_c('ul',_vm._l((items),function(ref,index){
var itemTitle = ref.title;
var remoteLink = ref.remoteLink;
var routePath = ref.routePath;
var urlSlug = ref.urlSlug;
return _c('li',{key:index,staticClass:"csn-support-sidebar-item",class:{
            'csn-support-sidebar-item-selected': urlSlug === _vm.currentRouteSlug,
            'csn-support-sidebar-item-no-link':
              !remoteLink && !routePath && !urlSlug,
          }},[(routePath)?_c('Route',{attrs:{"to":("" + _vm.locale + routePath)}},[_vm._v(" "+_vm._s(itemTitle)+" ")]):(urlSlug)?_c('Route',{attrs:{"to":{ name: _vm.supportRoute, params: { slug: urlSlug } }}},[_vm._v(" "+_vm._s(itemTitle)+" ")]):(remoteLink)?_c('a',{attrs:{"href":remoteLink}},[_vm._v(" "+_vm._s(itemTitle)+" ")]):_c('span',[_vm._v(" "+_vm._s(itemTitle)+" ")])],1)}),0)])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }