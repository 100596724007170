<template>
  <div class="csn-support-sidebar">
    <Loader v-if="isPending" />
    <div v-else>
      <div v-for="({ items, title }, index) in csmDictionary" :key="index">
        <span class="csn-support-sidebar-section">
          {{ title }}
        </span>
        <ul>
          <li
            v-for="({ title: itemTitle, remoteLink, routePath, urlSlug },
            index) in items"
            :key="index"
            class="csn-support-sidebar-item"
            :class="{
              'csn-support-sidebar-item-selected': urlSlug === currentRouteSlug,
              'csn-support-sidebar-item-no-link':
                !remoteLink && !routePath && !urlSlug,
            }"
          >
            <Route :to="`${locale}${routePath}`" v-if="routePath">
              {{ itemTitle }}
            </Route>
            <Route
              :to="{ name: supportRoute, params: { slug: urlSlug } }"
              v-else-if="urlSlug"
            >
              {{ itemTitle }}
            </Route>
            <a :href="remoteLink" v-else-if="remoteLink">
              {{ itemTitle }}
            </a>
            <span v-else>
              {{ itemTitle }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import {
  Module,
  CMS_GROUP_DICTIONARY,
  SET_CMS_GROUP_DICTIONARY,
} from '@/constants'
import { isEmpty } from '@/helpers'
import { RouteName } from '../../constants'
export default {
  name: 'SupportSidebar',
  components: {
    // Loader: () => import('@/components/Loader'),
    Route: () => import('@/components/Route'),
  },
  data: () => ({
    isPending: false,
  }),
  computed: {
    ...mapState(Module.CMS, [CMS_GROUP_DICTIONARY]),
    csmDictionary() {
      return this.CMS_GROUP_DICTIONARY?.items || {}
    },
    currentRouteSlug() {
      return this.$route?.params?.slug
    },
    supportRoute: () => RouteName.SUPPORT,
    locale() {
      const lang = this.$route?.params?.lang
      return lang ? `/${lang}` : '/en'
    },
  },
  methods: {
    ...mapActions(Module.CMS, [SET_CMS_GROUP_DICTIONARY]),
    handleRouteClick() {
      this.$emit('close')
    },
  },
  async created() {
    isEmpty(this.CMS_GROUP_DICTIONARY) && this.SET_CMS_GROUP_DICTIONARY()
  },
}
</script>
